import { DisplayName } from '@app/model/interfaces/leads';

export const leadGridViewColumns = [
  {
    dataFieldName: 'company',
    displayName: 'Business Name',
    isDefault: true,
    isSortable: true,
    hasFilter: true,
    fieldType: 'text',
    multiLanguageKey: 'leadTableColumnName.businessname',
  },
  {
    dataFieldName: 'leadOwner',
    displayName: 'Assigned To',
    isDefault: true,
    isSortable: true,
    hasFilter: true,
    fieldType: 'text',
    multiLanguageKey: 'leadTableColumnName.assignedTo',
  },
  {
    dataFieldName: 'firstName',
    displayName: 'Contact First Name',
    isDefault: true,
    isSortable: true,
    hasFilter: true,
    fieldType: 'text',
    multiLanguageKey: 'leadTableColumnName.contactfirstname',
  },
  {
    dataFieldName: 'lastName',
    displayName: 'Contact Last Name',
    isDefault: true,
    isSortable: true,
    hasFilter: true,
    fieldType: 'text',
    multiLanguageKey: 'leadTableColumnName.contactlastname',
  },
  {
    dataFieldName: 'phone',
    displayName: 'Phone Number',
    isDefault: true,
    isSortable: true,
    hasFilter: true,
    fieldType: 'text',
    multiLanguageKey: 'leadTableColumnName.phonenumber',
  },
  {
    dataFieldName: 'sequenceName',
    displayName: 'Sequence',
    isDefault: true,
    isSortable: true,
    hasFilter: true,
    fieldType: 'text',
    multiLanguageKey: 'shared.common.sequence',
  },
  {
    dataFieldName: 'campaignName',
    displayName: 'Campaign',
    isDefault: true,
    isSortable: true,
    hasFilter: true,
    fieldType: 'text',
    multiLanguageKey: 'leadTableColumnName.campaign',
  },
  {
    dataFieldName: 'dateCreated',
    displayName: 'Lead Created',
    isDefault: true,
    isSortable: true,
    hasFilter: true,
    fieldType: 'date',
    multiLanguageKey: 'leadTableColumnName.leadCreated',
  },
  {
    dataFieldName: 'createdByName',
    displayName: 'Created By',
    isSortable: true,
    hasFilter: true,
    fieldType: 'text',
    multiLanguageKey: 'leadTableColumnName.createdBy',
  },
  {
    dataFieldName: 'branchEmpName',
    displayName: 'Referred By',
    isSortable: true,
    hasFilter: true,
    fieldType: 'text',
    multiLanguageKey: 'leadTableColumnName.referredBy',
  },
  {
    dataFieldName: 'bankName',
    displayName: 'Partner Lookup',
    isSortable: true,
    hasFilter: true,
    fieldType: 'text',
    multiLanguageKey: 'shared.common.partnerlookup',
  },
  {
    dataFieldName: 'alliance',
    displayName: 'Alliance',
    isSortable: true,
    hasFilter: true,
    fieldType: 'text',
    multiLanguageKey: 'label.Alliance',
  },
  {
    dataFieldName: 'branchName',
    displayName: 'Branch',
    isSortable: true,
    hasFilter: true,
    fieldType: 'text',
    multiLanguageKey: 'shared.common.branch',
  },
  {
    dataFieldName: 'branchNumber',
    displayName: 'Branch ID',
    isSortable: true,
    hasFilter: true,
    fieldType: 'text',
    multiLanguageKey: 'leadTableColumnName.branchID',
  },
  {
    dataFieldName: 'age',
    displayName: 'leadAge',
    isSortable: true,
    hasFilter: false,
    fieldType: 'text',
    multiLanguageKey: 'leadTableColumnName.age',
  },
  {
    dataFieldName: 'address',
    displayName: 'Address Line 1',
    isSortable: true,
    hasFilter: true,
    fieldType: 'text',
    multiLanguageKey: 'shared.common.addressLine1',
  },
  {
    dataFieldName: 'city',
    displayName: 'City',
    isSortable: true,
    hasFilter: true,
    fieldType: 'text',
    multiLanguageKey: 'shared.common.city',
  },
  {
    dataFieldName: 'state',
    displayName: 'State',
    isSortable: true,
    hasFilter: true,
    fieldType: 'text',
    multiLanguageKey: 'shared.common.state',
  },
  {
    dataFieldName: 'mobile',
    displayName: 'Mobile',
    isSortable: true,
    hasFilter: true,
    fieldType: 'text',
    multiLanguageKey: 'leadTableColumnName.mobile',
  },
  {
    dataFieldName: 'email',
    displayName: 'Email',
    isSortable: true,
    hasFilter: true,
    fieldType: 'text',
    multiLanguageKey: 'shared.common.email',
  },
  {
    dataFieldName: 'lastContactedDate',
    displayName: 'Last Contacted Date',
    isSortable: true,
    hasFilter: false,
    fieldType: 'date',
    multiLanguageKey: 'leadTableColumnName.lastContactedDate',
  },
  {
    dataFieldName: 'daysSinceLastContact',
    displayName: 'Days Since Last Contact',
    isSortable: true,
    hasFilter: false,
    fieldType: 'text',
    multiLanguageKey: 'leadTableColumnName.daysSinceLastContact',
  },
  {
    dataFieldName: 'leadStatus',
    displayName: 'Lead Status',
    isSortable: false,
    hasFilter: false,
    fieldType: 'text',
    multiLanguageKey: 'leadTableColumnName.leadStatus',
  },
];

export const bestTimeList = [
  {
    bestCallTimeId: 1,
    bestCallTime: 'Immediately',
  },
  {
    bestCallTimeId: 2,
    bestCallTime: 'Anytime',
  },
  {
    bestCallTimeId: 3,
    bestCallTime: 'M-F 9-5',
  },
  {
    bestCallTimeId: 4,
    bestCallTime: 'Afternoons',
  },
  {
    bestCallTimeId: 5,
    bestCallTime: 'Mornings',
  },
  {
    bestCallTimeId: 6,
    bestCallTime: 'Days',
  },
  {
    id: 7,
    bestCallTime: 'Nights',
  },
];

export const leadFilterList = [
  {
    name: 'All Active Leads',
    key: 'allActiveLead',
    multiLanguageKey: 'leadFilterList.allActiveLead',
  },
  {
    name: 'New Leads',
    key: 'newLeads',
    multiLanguageKey: 'leadFilterList.newLeads',
  },
  {
    name: 'Tasks Due',
    key: 'tasksDue',
    multiLanguageKey: 'leadFilterList.tasksDue',
  },
  {
    name: 'High Rating Leads',
    key: 'highRatingLeads',
    multiLanguageKey: 'leadFilterList.highRatingLeads',
  },
  {
    name: 'Poor Data Quality',
    key: 'poorDataQuality',
    multiLanguageKey: 'leadFilterList.poorDataQuality',
  },
  {
    name: 'Digital Leads',
    key: 'digitalLeads',
    multiLanguageKey: 'leadFilterList.digitalLeads',
  },
  {
    name: 'Merchant Referral',
    key: 'merchantReferral',
    multiLanguageKey: 'leadFilterList.merchantReferral',
  },
  {
    name: 'Partner Referral',
    key: 'partnerReferral',
    multiLanguageKey: 'leadFilterList.partnerReferral',
  },
  {
    name: 'Self-Sourced Leads',
    key: 'selfSourcedLeads',
    multiLanguageKey: 'leadFilterList.selfSourcedLeads',
  },
  {
    name: 'Nurture In-Progress',
    key: 'nurturedLeads',
    multiLanguageKey: 'leadFilterList.nurturedLeads',
  },
  {
    name: 'Unqualified',
    key: 'unQualified',
    multiLanguageKey: 'leadFilterList.unQualified',
  },
];
export const leadFilterListForSD = [
  {
    name: 'All Active Leads',
    key: 'allActiveLead',
    multiLanguageKey: 'leadFilterList.allActiveLead',
  },
  {
    name: 'New Leads',
    key: 'newLeads',
    multiLanguageKey: 'leadFilterList.newLeads',
  },
  {
    name: 'High Rating Leads',
    key: 'highRatingLeads',
    multiLanguageKey: 'leadFilterList.highRatingLeads',
  },
  {
    name: 'Nurture In-Progress',
    key: 'nurturedLeads',
    multiLanguageKey: 'leadFilterList.nurturedLeads',
  },
  {
    name: 'Unqualified',
    key: 'unQualified',
    multiLanguageKey: 'leadFilterList.unQualified',
  },
];
export const filterByList = [
  {
    id: 1,
    dataFieldName: 'dateAssigned',
    displayName: 'Date Assigned',
    multiLanguageKey: 'agenda.prospecting.dateAssigned',
  },
  {
    id: 2,
    dataFieldName: 'dateCreated',
    displayName: 'Date Created',
    multiLanguageKey: 'grid.filter.dateCreated',
  },
  {
    id: 3,
    dataFieldName: 'lastContactedDate',
    displayName: 'Last Contacted',
    multiLanguageKey: 'grid.filter.lastContactedDate',
  },
  {
    id: 4,
    dataFieldName: 'lastModifiedDate',
    displayName: 'Date Modified',
    multiLanguageKey: 'grid.filter.lastModifiedDate',
  },
];

export const showingList = [
  {
    id: 1,
    name: 'Last 30 Days',
    multiLanguageKey: 'myReferral.year.last30Days',
  },
  {
    id: 2,
    name: 'Last 60 Days',
    multiLanguageKey: 'myReferral.year.last60Days',
  },
  {
    id: 3,
    name: 'All Time',
    multiLanguageKey: 'myReferral.year.alltime',
  },
  {
    id: 4,
    name: 'Custom Date Range',
    multiLanguageKey: 'myReferral.year.customDateRange',
  },
];
export const showingListMobile = [
  {
    id: 1,
    name: 'Last 30 Days',
    multiLanguageKey: 'myReferral.year.last30Days',
  },
  {
    id: 2,
    name: 'Last 60 Days',
    multiLanguageKey: 'myReferral.year.last60Days',
  },
  {
    id: 5,
    name: 'Last 90 Days',
    multiLanguageKey: 'myReferral.year.last90Days',
  },
  {
    id: 3,
    name: 'All Time',
    multiLanguageKey: 'myReferral.year.alltime',
  },
];

export const sortOptions = [
  {
    displayValue: 'By Age: Oldest First',
    value: 'dateCreated_ASC',
  },
  {
    displayValue: 'By Age: Newest First',
    value: 'dateCreated_DESC',
  },
  {
    displayValue: 'By Status: Attempted Contact First',
    value: 'AttemptedContactSort_ASC',
  },
  {
    displayValue: 'By Status: Open First',
    value: 'OpenSort_ASC',
  },
];
export const multiFilterOptions = {
  leadStatus: [
    { code: 'Open', desc: 'Open' },
    { code: 'Attempted to Contact', desc: 'Attempted to Contact' },
    { code: 'New', desc: 'Nurture' },
    { code: 'Unqualified', desc: 'Unqualified' },
  ],
};
export const leadDetailsHeader = [
  {
    dataFieldName: 'title',
    displayName: 'Reminder',
  },
  {
    dataFieldName: 'type',
    displayName: 'Type',
  },
  {
    dataFieldName: 'when',
    displayName: 'When',
  },
  {
    dataFieldName: 'notes',
    displayName: 'Notes',
  },
  {
    dataFieldName: 'action',
    displayName: 'Action',
  },
];

export const remindersCompletedListColumns = [
  {
    dataFieldName: 'title',
    displayName: 'Reminder',
    fieldType: 'text',
    isSortable: true,
    hasFilter: true,
    showFilter: false,
  },
  {
    dataFieldName: 'when',
    displayName: 'When',
    fieldType: 'text',
    isSortable: true,
    hasFilter: true,
    showFilter: false,
  },
  {
    dataFieldName: 'notes',
    displayName: 'Notes',
    fieldType: 'text',
    isSortable: true,
    hasFilter: true,
    showFilter: false,
  },
  {
    dataFieldName: 'action',
    displayName: 'Action',
    isSortable: false,
    hasFilter: false,
    hasAction: true,
    data: [
      {
        name: 'delete',
        path: 'assets/images/icons/icon_blue_trash.svg',
      },
    ],
  },
];

export const BranchReminderHeader = [
  {
    dataFieldName: 'title',
    displayName: 'Reminder',
  },
  {
    dataFieldName: 'when',
    displayName: 'When',
  },
  {
    dataFieldName: 'notes',
    displayName: 'Notes',
  },
  {
    dataFieldName: 'action',
    displayName: 'Action',
  },
];
export const listIndustrytype = [
  {
    name: 'Restaurants',
    value: 'restaurant',
    multilanguageKey: 'lead.create.new.listIndustrytype.restaurant',
  },
  {
    name: 'General Retail and Wholesale',
    value: 'restaurant',
    multilanguageKey: 'lead.create.new.listIndustrytype.restaurant',
  },
  {
    name: 'Leisure',
    value: 'restaurant',
    multilanguageKey: 'lead.create.new.listIndustrytype.restaurant',
  },
  {
    name: 'Other Services',
    value: 'restaurant',
    multilanguageKey: 'lead.create.new.listIndustrytype.restaurant',
  },
  {
    name: 'Grocery and Convenience Stores',
    value: 'restaurant',
    multilanguageKey: 'lead.create.new.listIndustrytype.restaurant',
  },
  {
    name: 'Medical Services',
    value: 'restaurant',
    multilanguageKey: 'lead.create.new.listIndustrytype.restaurant',
  },

  {
    name: 'Vehicles Servicing',
    value: 'restaurant',
    multilanguageKey: 'lead.create.new.listIndustrytype.restaurant',
  },
  {
    name: 'Mail Order / Direct Selling',
    value: 'restaurant',
    multilanguageKey: 'lead.create.new.listIndustrytype.restaurant',
  },
  {
    name: 'Professional Services',
    value: 'restaurant',
    multilanguageKey: 'lead.create.new.listIndustrytype.restaurant',
  },
  {
    name: 'Travel',
    value: 'restaurant',
    multilanguageKey: 'lead.create.new.listIndustrytype.restaurant',
  },
];

export const leadTags = [
  {
    name: '#CloverFlex',
    value: 'call',
    multilanguageKey: 'shared.common.call',
  },
  {
    name: '#CloverDuo',
    value: 'chat',
    multilanguageKey: 'shared.common.text',
  },
  {
    name: '#Restaurant',
    value: 'mail',
    multilanguageKey: 'shared.common.email',
  },
  {
    name: '#Promotion',
    value: 'mail',
    multilanguageKey: 'shared.common.email',
  },
];
export const contactDetails = [
  {
    name: 'Call',
    value: 'call',
    multilanguageKey: 'shared.common.call',
  },
  {
    name: 'Chat',
    value: 'chat',
    multilanguageKey: 'shared.common.text',
  },
  {
    name: 'Mail',
    value: 'mail',
    multilanguageKey: 'shared.common.email',
  },
];

export const preferredMethod = [
  {
    displayValue: 'Cell',
    value: 'mobile',
  },
  {
    displayValue: 'Landline',
    value: 'phone',
  },
];

export const titleList = [
  {
    displayValue: 'Owner',
    value: 'owner',
  },
  {
    displayValue: 'Executive',
    value: 'executive',
  },
  {
    displayValue: 'Manager',
    value: 'manager',
  },
  {
    displayValue: 'Other',
    value: 'other',
  },
];
export const agreementTableHeader = [
  {
    displayValue: 'Owner Name',
    dataFieldName: 'ownername',
  },
  {
    displayValue: 'Email',
    dataFieldName: 'email',
  },
  {
    displayValue: 'Status',
    dataFieldName: 'status',
  },
  {
    displayValue: 'Actions',
    dataFieldName: 'action',
  },
];

export const activeEligibleCampaign = [
  {
    dataFieldName: 'name',
    displayName: 'Campaign Name',
    defaultDisplayOrder: 0,
    type: 'link',
    hasFilter: true,
    isSortable: true,
    fieldType: 'text',
    multiLanguageKey: 'referral.campaignName',
  },
  {
    dataFieldName: 'offers',
    displayName: 'Promotions',
    defaultDisplayOrder: 1,
    isSortable: false,
    type: 'offers',
    multiLanguageKey: 'campaign.create.form.promotions.heading',
  },
  {
    dataFieldName: 'startDate',
    displayName: 'Start Date',
    defaultDisplayOrder: 2,
    type: 'date',
    isSortable: true,
    multiLanguageKey: 'campaign.create.form.startDate',
  },
  {
    dataFieldName: 'endDate',
    displayName: 'End Date',
    defaultDisplayOrder: 3,
    type: 'date',
    isSortable: true,
    multiLanguageKey: 'shared.common.endDate',
  },
  {
    dataFieldName: 'action',
    displayName: 'Action',
    type: 'action',
    defaultDisplayOrder: 4,
    multiLanguageKey: 'reminderTable.action',
  },
];
export const activeLeadEligibleCampaign = [
  {
    dataFieldName: 'name',
    displayName: 'Campaign Name',
    defaultDisplayOrder: 0,
    type: 'link',
    hasFilter: true,
    isSortable: true,
    fieldType: 'text',
    multiLanguageKey: 'referral.campaignName',
  },
  {
    dataFieldName: 'offers',
    displayName: 'Promotions',
    defaultDisplayOrder: 1,
    isSortable: false,
    type: 'offers',
    multiLanguageKey: 'campaign.create.form.promotions.heading',
  },
  {
    dataFieldName: 'campaignOwner',
    displayName: 'Owner',
    defaultDisplayOrder: 2,
    type: 'text',
    fieldType: 'text',
    hasFilter: true,
    isSortable: true,
    multiLanguageKey: 'opportunity.leadCampaign.leadInformation.owner',
  },
  {
    dataFieldName: 'description',
    displayName: 'Description',
    defaultDisplayOrder: 3,
    type: 'text',
    fieldType: 'text',
    hasFilter: true,
    isSortable: true,
    multiLanguageKey: 'opportunities.tab.status.pricing.override.description',
  },
  {
    dataFieldName: 'startDate',
    displayName: 'Start Date',
    defaultDisplayOrder: 4,
    type: 'date',
    fieldType: 'date',
    hasFilter: true,
    isSortable: true,
    multiLanguageKey: 'campaign.create.form.startDate',
  },
  {
    dataFieldName: 'endDate',
    displayName: 'End Date',
    defaultDisplayOrder: 5,
    type: 'date',
    fieldType: 'date',
    hasFilter: true,
    isSortable: true,
    multiLanguageKey: 'shared.common.endDate',
  },
  {
    dataFieldName: 'action',
    displayName: 'Action',
    type: 'action',
    defaultDisplayOrder: 6,
    multiLanguageKey: 'reminderTable.action',
  },
];
export const uploadedFileListHeader = [
  {
    dataFieldName: 'title',
    displayName: 'File Name',
  },
  {
    dataFieldName: 'category',
    displayName: 'Category',
  },
  {
    dataFieldName: 'createdDate',
    displayName: 'Date Uploaded',
  },
  {
    dataFieldName: 'ownerName',
    displayName: 'Uploaded By',
  },
  {
    dataFieldName: 'action',
    displayName: 'Action',
  },
];
export const uploadedMarketPlaceFileListHeader = [
  {
    dataFieldName: 'title',
    displayName: 'File Name',
  },
  {
    dataFieldName: 'category',
    displayName: 'Category',
  },
  {
    dataFieldName: 'createdDate',
    displayName: 'Date Uploaded',
  },
  {
    dataFieldName: 'ownerName',
    displayName: 'Uploaded By',
  },
  {
    dataFieldName: 'documentUploadComments',
    displayName: 'Comments',
  },
];

export const leadActivityTab = [
  {
    dataFieldName: 'all',
    dataDisplayName: 'All',
    multiLanguageKey: 'log.activity.all',
  },
  {
    dataFieldName: 'calls',
    dataDisplayName: 'Calls',
    multiLanguageKey: 'log.activity.calls',
  },
  {
    dataFieldName: 'emails',
    dataDisplayName: 'Emails',
    multiLanguageKey: 'log.activity.emails',
  },
  {
    dataFieldName: 'texts',
    dataDisplayName: 'Texts',
    multiLanguageKey: 'log.activity.chats',
  },
  {
    dataFieldName: 'notes',
    dataDisplayName: 'Notes',
    multiLanguageKey: 'log.activity.notes',
  },
  {
    dataFieldName: 'digitalActivity',
    dataDisplayName: 'Digital Activity',
    multiLanguageKey: 'log.activity.digital',
  },
];

export const marketPlaceTabset = {
  applicationSigned: 'Agreement',
  shipped: 'Delivery Status',
  delivered: 'Activation Status',
};

export const agendaTaskIcons = {
  imageSuccess: 'assets/images/icons/icon_succes.png',
  imageMetMerchant: 'assets/images/icons/icon_metmerchant.svg',
  imageUnableTomeetMerchant: 'assets/images/icons/icon_unabletoMerchant.svg',
};
export const activeEligibleSequneces = [
  {
    dataFieldName: 'sequenceName',
    displayName: 'Sequence Name',
    defaultDisplayOrder: 0,
    type: 'link',
    hasFilter: true,
    isSortable: true,
    fieldType: 'text',
    multiLanguageKey: 'sequence.title.name',
  },
  {
    dataFieldName: 'stepCount',
    displayName: 'Number of Tasks',
    defaultDisplayOrder: 1,
    isSortable: false,
    hasFilter: false,
    fieldType: 'text',
    min: 1,
    max: 20,
    type: 'text',
    multiLanguageKey: 'campaign.sales.sequence.no.of.tasks',
  },
  {
    dataFieldName: 'createdBy',
    displayName: 'Created By',
    defaultDisplayOrder: 2,
    type: 'text',
    isSortable: true,
    fieldType: 'text',
    hasFilter: true,
    multiLanguageKey: 'leadTableColumnName.createdBy',
  },
  {
    dataFieldName: 'action',
    displayName: 'Action',
    type: 'action',
    defaultDisplayOrder: 5,
    multiLanguageKey: 'reminderTable.action',
  },
];

export const activeEligibleSequnecesAgendaTask = [
  {
    dataFieldName: 'sequenceName',
    displayName: 'Sequence Name',
    defaultDisplayOrder: 0,
    type: 'link',
    hasFilter: true,
    isSortable: true,
    fieldType: 'text',
    multiLanguageKey: 'sequence.title.name',
  },
  {
    dataFieldName: 'stepCount',
    displayName: 'Number of Tasks',
    defaultDisplayOrder: 1,
    isSortable: false,
    hasFilter: false,
    fieldType: 'text',
    min: 1,
    max: 20,
    type: 'text',
    multiLanguageKey: 'campaign.sales.sequence.no.of.tasks',
  },
  {
    dataFieldName: 'createdBy',
    displayName: 'Created By',
    defaultDisplayOrder: 2,
    type: 'text',
    isSortable: true,
    fieldType: 'text',
    hasFilter: true,
    multiLanguageKey: 'leadTableColumnName.createdBy',
  },
  {
    dataFieldName: 'action',
    displayName: 'Action',
    type: 'action',
    defaultDisplayOrder: 5,
    multiLanguageKey: 'reminderTable.action',
  },
];
export const changeEligibleSequneces = [
  {
    dataFieldName: 'sequenceName',
    displayName: 'Sequence Name',
    defaultDisplayOrder: 0,
    type: 'link',
    hasFilter: true,
    isSortable: true,
    fieldType: 'text',
    multiLanguageKey: 'sequence.title.name',
  },

  {
    dataFieldName: 'createdBy',
    displayName: 'Created By',
    defaultDisplayOrder: 1,
    type: 'text',
    fieldType: 'text',
    isSortable: true,
    hasFilter: true,
    multiLanguageKey: 'leadTableColumnName.createdBy',
  },
  {
    dataFieldName: 'description',
    displayName: 'Description',
    defaultDisplayOrder: 2,
    type: 'text',
    isSortable: true,
    fieldType: 'text',
    hasFilter: true,
    multiLanguageKey: 'agenda.campaignInformation.description',
  },
  {
    dataFieldName: 'createdDate',
    displayName: 'Date Created',
    defaultDisplayOrder: 3,
    type: 'date',
    fieldType: 'date',
    isSortable: true,
    hasFilter: true,
    multiLanguageKey: 'grid.filter.dateCreated',
  },
  {
    dataFieldName: 'stepCount',
    displayName: 'Number of Tasks',
    defaultDisplayOrder: 5,
    isSortable: false,
    hasFilter: false,
    fieldType: 'number',
    multiLanguageKey: 'campaign.sales.sequence.no.of.tasks',
  },
  {
    dataFieldName: 'action',
    displayName: 'Action',
    type: 'action',
    defaultDisplayOrder: 7,
    multiLanguageKey: 'reminderTable.action',
  },
];

export const duplicateLeadGridViewColumns = [
  {
    dataFieldName: 'legalBusinessName',
    displayName: 'Business Name',
    isSortable: false,
    hasFilter: false,
    fieldType: 'text',
    multiLanguageKey: 'leadTableColumnName.businessname',
  },
  {
    dataFieldName: 'phone',
    displayName: 'Phone Number',
    isSortable: false,
    hasFilter: false,
    fieldType: 'text',
    multiLanguageKey: 'leadTableColumnName.phonenumber',
  },
  {
    dataFieldName: 'email',
    displayName: 'Email',
    isSortable: false,
    hasFilter: false,
    fieldType: 'text',
    width: 250,
    multiLanguageKey: 'shared.common.email',
  },
  {
    dataFieldName: 'leadType',
    displayName: 'Lead Type',
    isSortable: false,
    hasFilter: false,
    fieldType: 'text',
    multiLanguageKey: 'shared.common.leadType',
  },
  {
    dataFieldName: 'partner',
    displayName: 'Partner',
    isSortable: false,
    hasFilter: false,
    fieldType: 'text',
    multiLanguageKey: 'shared.common.partner',
  },
  {
    dataFieldName: 'lastContactedDate',
    displayName: 'Last Contacted Date',
    isSortable: false,
    hasFilter: false,
    fieldType: 'date',
    dateFormat: 'MMM dd, yyyy',
    multiLanguageKey: 'leadTableColumnName.lastContactedDate',
  },
  {
    dataFieldName: 'leadStatus',
    displayName: 'Lead Status',
    isSortable: false,
    hasFilter: false,
    fieldType: 'text',
    multiLanguageKey: 'leadTableColumnName.leadStatus',
  },
  {
    dataFieldName: 'ownerName',
    displayName: 'Assigned To',
    isSortable: false,
    hasFilter: false,
    fieldType: 'text',
    multiLanguageKey: 'leadTableColumnName.assignedTo',
  },
];

const commonStaticContent = {
  somethingApiError: 'something.wrong',
  cancel: 'common.button.text.cancel',
  confirm: 'lead.assign.assign.button',
  removeButton: 'shared.common.text.remove',
  deleteButton: 'shared.common.text.delete',
};

export const activceCampaignDialogStaticContent = {
  ...commonStaticContent,
  leadAssignConfirm: 'lead.assign.confirm',
  leadAssignConfirmMessage: 'lead.assign.confirm.message',
  leadUnAssign: 'shared.common.text.remove',
  leadUnAssignConfirm: 'lead.leadCampaign.unassign',
  leadUnAssignConfirmMessage: 'lead.unassign.campaign.message',
  listOfCampaigns: 'lead.text.list.of.campaigns',
};

export const activceSequenceDialogStaticContent = {
  ...commonStaticContent,
  assigningSequenceText: 'sequence.assigning.sequence',
  leadAssignConfirmMessage: 'assign.confirm.sequence.message',
  subheading: 'lead.text.list.of.sequences',
  leadAssignConfirm: 'lead.assign.confirm.sequence',
  leadAssignMessage: 'assign.sequence.message',
  opportunitiesBulkAssignMessage: 'bulk.assign.opportunity.sequence.message',
  leadBulkAssignMessage: 'bulk.assign.lead.sequence.message',
  leadRemoveMessage: 'remove.sequence.message',
};

export const LeadAdditionalDetailsPopupStaticContent = {
  taskUpdateMesage: 'lead.update.message',
  requireBranchNumber: 'lead.details.additionDetails.bankBranchNo.required',
  requireBranchName: 'lead.details.additionDetails.bankBranch.required',
  invalidBranchName: 'bank.branch.invalid',
  invalidBankEmpName: 'bank.emp.name.invalid',
  invalidHierarchyAgent: 'invalid.hierarchy.agent',
  requiredSubIndustry: 'lead.create.text.subIndustry.required',
};

export const LeadDetailsStaticContent = {
  ...commonStaticContent,
  taskUpdateMesage: 'lead.update.message',
  confirmLeadReAssign: 'lead.detail.lead.assign.confirm.title',
  leadAssignMessage: 'lead.detail.lead.assign.confirm.message',
  reassign: 'button.reassign',
  ok: 'shared.ok',
  callInProgress: 'call.inprogress',
  salesForceAutoRefershText: 'salesforce.lead.event.auto.refresh.text.message',
  confirmListRemove: 'lead.detail.lead.assign.confirm.title',
  prospectSummaryError: 'prospect.summary.error',
  accessDenied: 'opportunity.denied',
  deniedMessage: 'opportunity.denied.message',
  agreementMessage: 'opportunity.agreement.has.sent.message',
  loadingDuplicateLeads: 'loading.duplicate.leads',
  potentialDuplicateLeads: 'potential.duplicate.leads',
  potentialDuplicateLeadsDesc: 'potential.duplicate.leads.desc',
};

export const LeadSequenceStaticContent = {
  ...commonStaticContent,
  assigningSequenceText: 'sequence.assigning.sequence',
  agendaSequenceTitle: 'agenda.name.title',
  removingSequenceText: 'sequence.removing.sequence',
  leadNoAvailable: 'lead.no.sequence',
  leadAssignConfirm: 'lead.assign.confirm.sequence',
  waitFor: 'shared.waitfor',
  nextTask: 'shared.nextTask',
  hours: 'shared.hrs',
  hour: 'shared.hr',
  days: 'shared.common.text.days',
  day: 'shared.common.text.day',
  minutes: 'shared.mins',
  minute: 'shared.minad',
  leadUnAssign: 'shared.common.text.remove',
  leadAssignMessage: 'assign.sequence.message',
  leadAssignConfirmMessage: 'assign.confirm.sequence.message',
  leadRemoveConfirmMessage: 'remove.confirm.sequence.message',
  leadUnAssignConfirm: 'lead.leadCampaign.unassign',
  leadUnAssignConfirmMessage: 'lead.unassign.campaign.message',
};

export const leadViewGridStaticContent = {
  ...commonStaticContent,
  daysTitle: 'shared.common.text.days',
  dayTitle: 'shared.common.text.day',
  weeksTitle: 'shared.weeks',
  weekTitle: 'shared.week',
  monthsTitle: 'shared.months',
  monthTitle: 'shared.month',
  yearsTitle: 'shared.years',
  yearTitle: 'shared.year',
  lastContactedDays: 'agenda.common.days',
  lastContactedDay: 'shared.common.text.day',
  callInProgress: 'call.inprogress',
  isBcNameInvalid: 'bc.name.invaild',
  downloadMsgSuccess: 'referral.msg.download.success',
};

export const newLeadStaticContent = {
  ...commonStaticContent,
  phoneEmailError: 'lead.create.emailPhoneHint',
  partnerSelectorError: 'campaign.create.form.partnerSelector.not.valid',
  successLeadText: 'lead.create.success',
  requireHierarchyAgent: 'lead.create.select.hierarchyAgent',
};

export const leadConvertLabelStaticContent = {
  firstName: 'referralTableColumnName.firstName',
  lastName: 'referralTableColumnName.lastName',
  phone: 'referralTableColumnName.phone',
  email: 'shared.common.email',
  legalBusinessName: 'shared.common.legal.business.name',
  businessPhone: 'shared.common.business.phn.number',
  address: 'shared.address',
  zip: 'opportunityTableColumnName.zip',
  mccCategoryID: 'opportunity.industryType',
  mccDescriptionId: 'lead.create.text.subIndustry',
  leadConversionInprogressMsg: 'lead.conversion.msg.inprogress'
};

export const getGridViewColumns = (
  canGetReporteeEntities: boolean,
  isDefault: boolean
): DisplayName[] => {
  let columnList = canGetReporteeEntities
    ? leadGridViewColumns
    : leadGridViewColumns.filter(
        (column: DisplayName) => column.dataFieldName != 'leadOwner'
      );
  if (isDefault) {
    columnList = leadGridViewColumns.filter(
      (column: DisplayName) => column.isDefault
    );
  }
  return columnList;
};
