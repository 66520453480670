<div class="page-container" *ngIf="!isPopup">
  <div class="d-flex justify-content-between align-items-center">
    <h1 class="page-title">
      {{ 'notification.allNotifications' | translate }} ({{
        hasMoaPriviledge
          ? notificationResultList.length
          : notificationResultList.length - moaList.length
      }})
    </h1>
    <span
      *ngIf="showNotification"
      class="p-2 rounded notification-setting-icon pointer"
      (click)="getNotificationSettings()"
      title="Notification Settings"
    >
      <img
        src="../../../assets/images/icons/icon_notification_settings.svg"
        width="20px"
        height="20px"
      />
    </span>
  </div>
  <div *ngIf="!isPopup" class="my-3">
    <a href="javascript:void();" class="link" (click)="clearNotification()">{{
      'shared.common.clearall' | translate
    }}</a>
  </div>
</div>
<div [ngClass]="{ 'mx-2 mb-2': isPopup }" [class.card]="!isPopup">
  <div class="positionRelative mt-3" *ngIf="!isPopup && showLoader">
    <mat-spinner diameter="20"></mat-spinner>
  </div>
  <mat-tab-group
    *ngIf="!showLoader"
    class="cloverTabset maxHeightNotification"
    mat-stretch-tabs="false"
    mat-align-tabs="start"
    [selectedIndex]="selectedIndex"
    (selectedIndexChange)="selectedIndex = $event"
  >
    <mat-tab *ngFor="let tab of activeTabs; let i = index">
      <ng-template mat-tab-label>
        <span
          class="fs-12 me-1 p-1"
          [ngClass]="{
            count: notificationResultList.length < 99,
            square: notificationResultList.length > 99
          }"
        >
          {{
            i === 0
              ? hasMoaPriviledge
                ? moaListCount
                : nonMoaList.length
              : nonMoaList.length
          }}
        </span>
        {{ tab.tab }}
      </ng-template>
      <div
        *ngIf="hasMoaPriviledge && selectedIndex === 0"
        [class.px-2]="isPopup"
      >
        <div *ngIf="moaList.length > 0">
          <div
            class="notification-container p-2 d-flex pointer"
            *ngFor="let item of moaList; let i = index"
            [ngClass]="{
              'border rounded': !isPopup,
              'mb-2': !isPopup && i < moaList.length - 1,
              'highlight-tile': !item.isVisited,
              'border-bottom': isPopup && i < moaList.length - 1
            }"
            (click)="readNotification(item, false)"
          >
            <div class="notification-msg-icon">
              <img
                src="../../../assets/images/icons/icon_task_1.svg"
                alt=""
                width="22px"
                height="22px"
              />
            </div>
            <div class="notification-text fs-14">
              <div class="textPrimary fs-12 bold">
                {{ 'notification.heading.newList.assigned' | translate }}
              </div>
              <div
                class="text-wrap"
                [innerHTML]="item.notificationBodyParsed?.textTemplate"
              ></div>
              <div class="small text-gray-500">{{ item.formattedDate }}</div>
            </div>
          </div>
        </div>
        <div *ngIf="moaList.length === 0">
          <clover-no-data
            space="p-2"
            message="{{ 'notifications.nonotification' | translate }}"
          ></clover-no-data>
        </div>
      </div>
      <div
        *ngIf="!hasMoaPriviledge && selectedIndex === 0"
        [class.px-2]="isPopup"
      >
        <div
          *ngIf="
            notificationActionList.length === 0 &&
            notificationDownLaodList.length === 0
          "
        >
          <clover-no-data
            space="p-2"
            message="{{ 'notifications.nonotification' | translate }}"
          ></clover-no-data>
        </div>
        <ng-container *ngIf="notificationActionList.length > 0">
          <h6 class="fs-14 fw-bold">{{ 'shared.actions' | translate }}</h6>
          <div
            *ngIf="
              notificationActionList.length > 0 ||
                notificationDownLaodList.length > 0;
              else noOtherNotifications
            "
          >
            <div
              *ngFor="let item of notificationActionList; let i = index"
              [ngClass]="{
                'border rounded': !isPopup,
                'mb-2': !isPopup && i < notificationActionList.length - 1,
                'highlight-tile': !item.isVisited,
                'border-bottom':
                  isPopup && i < notificationActionList.length - 1
              }"
              class="notification-container p-2 d-flex pointer"
              (click)="readNotification(item, false)"
            >
              <div
                class="notification-msg-icon"
                [ngClass]="{ bgImage: !isPopup||(!item.hasImage && isPopup), 'me-3': isPopup,}"
              >
                <ng-container *ngIf="item.notificationGroupName === 'Campaign'">
                  <img
                    *ngIf="item.hasImage; else noImage"
                    class="profilePic mt-0"
                    [src]="getEncodingURL(item.profileImage)"
                  />
                  <ng-template #noImage>
                    <img class="mt-0" src="assets/images/icons/icon_user.svg" />
                  </ng-template>
                </ng-container>
                <ng-container
                  *ngIf="
                    item.notificationGroupName === 'Agenda' ||
                    item.notificationGroupName === 'Activity' ||
                    item.notificationGroupName === 'Audience' ||
                    item.notificationGroupName === 'Lead' ||
                    item.notificationGroupName === 'Branch'
                  "
                >
                  <img
                    class="mt-0"
                    src="assets/images/icons/icon_notiRecommendedTask.svg"
                  />
                </ng-container>
                <ng-container
                  *ngIf="item.notificationGroupName === 'Opportunity'"
                >
                  <img
                    *ngIf="item.notificationBodyParsed?.iconName"
                    class="mt-0"
                    src="assets/images/icons/noti-{{
                      item.notificationBodyParsed?.iconName
                    }}.svg"
                  />
                  <img
                    *ngIf="!item.notificationBodyParsed?.iconName"
                    class="mt-0"
                    src="assets/images/icons/icon_notiRecommendedTask.svg"
                  />
                </ng-container>
                <ng-container
                  *ngIf="item.notificationGroupName === 'RiskAlerts' ||
                  item.notificationGroupName === 'RiskAlertsBatch'"
                >
                  <img
                    *ngIf="item.notificationBodyParsed?.iconName"
                    class="mt-0"
                    src="assets/images/icons/noti-{{
                      item.notificationBodyParsed?.iconName
                    }}.svg"
                  />
                  <img
                    *ngIf="!item.notificationBodyParsed?.iconName"
                    class="mt-0"
                    src="assets/images/icons/noti-icon_creditRejected.svg"
                  />
                </ng-container>

                <mat-spinner
                  [hidden]="!item.isLoading"
                  diameter="40"
                ></mat-spinner>
              </div>
              <div class="notification-text fs-14">
                <div class="textPrimary fs-12 bold">
                  {{
                    (item.notificationGroupName === 'Agenda'
                      ? 'notification.heading.newAgenda.assigned'
                      : item.notificationGroupName === 'Campaign'
                      ? 'notification.heading.newCampaign.assigned'
                      : item.notificationGroupName === 'Activity'
                      ? 'notification.heading.newActivity.assigned'
                      : item.notificationGroupName === 'Branch'
                      ? 'notification.heading.newBranch.assigned'
                      : item.notificationGroupName === 'Opportunity'
                      ? 'notification.heading.newOpportunity.assigned'
                      : item.notificationGroupName === 'Lead'
                      ? 'notification.heading.newLead.assigned'
                      : item.notificationGroupName === 'RiskAlerts' || 
                        item.notificationGroupName === 'RiskAlertsBatch' 
                      ? getRiskAlertsTitleText(item)
                      :  item.notificationGroupName === 'LeadBulkAssigned'
                      ? 'notification.heading.bulk.assigned.leads'
                      : 'notification.heading.newAudience.assigned'
                    ) | translate
                  }}
                </div>
                <div class="text-left text-wrap line-height-normal">
                  {{ item.notificationBodyParsed?.textTemplate }}
                </div>
                <div class="small text-gray-500">{{ item.formattedDate }}</div>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="notificationDownLaodList.length > 0">
          <h6
            class="fs-14 fw-bold"
            [class.mt-3]="notificationActionList.length > 0"
          >
            {{ 'notifications.downloads' | translate }}
          </h6>
          <div
            *ngFor="let item of notificationDownLaodList; let i = index"
            [ngClass]="{
              'border rounded': !isPopup,
              'mb-2': !isPopup && i < notificationDownLaodList.length - 1,
              'highlight-tile': !item.isVisited,
              'border-bottom':
                isPopup && i < notificationDownLaodList.length - 1
            }"
            class="notification-container p-2 d-flex pointer"
            (click)="readNotification(item, false)"
          >
            <div
              class="notification-msg-icon"
              [ngClass]="{ bgImage: !isPopup ||!item.hasImage && isPopup, 'mt-2 me-2': isPopup, }"
            >
              <img
                *ngIf="item.hasImage"
                class="mt-0 profilePic"
                [src]="getEncodingURL(item.profileImage)"
              />
              <mat-spinner
                [hidden]="!item.isLoading"
                diameter="40"
              ></mat-spinner>
              <img
                *ngIf="!item.hasImage"
                class="mt-0 profilePic"
                src="assets/images/icons/icon_user.svg"
              />
            </div>
            <div class="notification-text fs-14">
              <div class="textPrimary fs-12 bold">
                {{ 'notification.heading.newDownload.assigned' | translate }}
              </div>
              <div class="text-left text-wrap line-height-normal">
                {{ item.notificationBodyParsed?.textTemplate }}
              </div>
              <div class="small text-gray-500">{{ item.formattedDate }}</div>
            </div>
          </div>
        </ng-container>
        <ng-template #noOtherNotifications>
          {{ 'notifications.nonotification' | translate }}
        </ng-template>
      </div>
      <div *ngIf="selectedIndex === 1" [class.px-2]="isPopup">
        <div
          *ngIf="
            notificationActionList.length === 0 &&
            notificationDownLaodList.length === 0
          "
        >
          <clover-no-data
            space="p-2"
            message="{{ 'notifications.nonotification' | translate }}"
          ></clover-no-data>
        </div>
        <ng-container *ngIf="notificationActionList.length > 0">
          <h6 class="fs-14 fw-bold">{{ 'shared.actions' | translate }}</h6>
          <div
            *ngIf="
              notificationActionList.length > 0 ||
                notificationDownLaodList.length > 0;
              else noOtherNotifications
            "
          >
            <div
              *ngFor="let item of notificationActionList; let i = index"
              [ngClass]="{
                'border rounded': !isPopup,
                'mb-2': !isPopup && i < notificationActionList.length - 1,
                'highlight-tile': !item.isVisited,
                'border-bottom':
                  isPopup && i < notificationActionList.length - 1
              }"
              class="notification-container p-2 d-flex pointer"
              (click)="readNotification(item, false)"
            >
              <div
                class="notification-msg-icon"
                [ngClass]="{
                  bgImage: !isPopup || (!item.hasImage && isPopup),
                  'me-3': isPopup
                }"
              >
                <ng-container *ngIf="item.notificationGroupName === 'Campaign'">
                  <img
                    *ngIf="item.hasImage; else noImage"
                    class="profilePic mt-0"
                    [src]="getEncodingURL(item.profileImage)"
                  />
                  <ng-template #noImage>
                    <img class="mt-0" src="assets/images/icons/icon_user.svg" />
                  </ng-template>
                </ng-container>
                <ng-container
                  *ngIf="
                    item.notificationGroupName === 'Agenda' ||
                    item.notificationGroupName === 'Activity' ||
                    item.notificationGroupName === 'Audience' ||
                    item.notificationGroupName === 'Lead' ||
                    item.notificationGroupName === 'Branch'
                  "
                >
                  <img
                    class="mt-0"
                    src="assets/images/icons/icon_notiRecommendedTask.svg"
                  />
                </ng-container>
                <ng-container
                  *ngIf="item.notificationGroupName === 'Opportunity'"
                >
                  <img
                    *ngIf="item.notificationBodyParsed?.iconName"
                    class="mt-0"
                    src="assets/images/icons/noti-{{
                      item.notificationBodyParsed?.iconName
                    }}.svg"
                  />
                  <img
                    *ngIf="!item.notificationBodyParsed?.iconName"
                    class="mt-0"
                    src="assets/images/icons/icon_notiRecommendedTask.svg"
                  />
                </ng-container>
                <ng-container
                  *ngIf="item.notificationGroupName === 'RiskAlerts' ||
                  item.notificationGroupName === 'RiskAlertsBatch'"
                >
                  <img
                    *ngIf="item.notificationBodyParsed?.iconName"
                    class="mt-0"
                    src="assets/images/icons/noti-{{
                      item.notificationBodyParsed?.iconName
                    }}.svg"
                  />
                  <img
                    *ngIf="!item.notificationBodyParsed?.iconName"
                    class="mt-0"
                    src="assets/images/icons/noti-icon_creditRejected.svg"
                  />
                </ng-container>

                <mat-spinner
                  [hidden]="!item.isLoading"
                  diameter="40"
                ></mat-spinner>
              </div>
              <div class="notification-text fs-14">
                <div class="textPrimary fs-12 bold">
                  {{
                    (item.notificationGroupName === 'Agenda'
                      ? 'notification.heading.newAgenda.assigned'
                      : item.notificationGroupName === 'Campaign'
                      ? 'notification.heading.newCampaign.assigned'
                      : item.notificationGroupName === 'Activity'
                      ? 'notification.heading.newActivity.assigned'
                      : item.notificationGroupName === 'Branch'
                      ? 'notification.heading.newBranch.assigned'
                      : item.notificationGroupName === 'Opportunity'
                      ? 'notification.heading.newOpportunity.assigned'
                      : item.notificationGroupName === 'Lead'
                      ? 'notification.heading.newLead.assigned'
                      : item.notificationGroupName === 'RiskAlerts' || 
                        item.notificationGroupName === 'RiskAlertsBatch' 
                      ? getRiskAlertsTitleText(item)
                      : 'notification.heading.newAudience.assigned'
                    ) | translate
                  }}
                </div>
                <div class="text-left text-wrap line-height-normal">
                  {{ item.notificationBodyParsed?.textTemplate }}
                </div>
                <div class="small text-gray-500">{{ item.formattedDate }}</div>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="notificationDownLaodList.length > 0">
          <h6
            class="fs-14 fw-bold"
            [class.mt-3]="notificationActionList.length > 0"
          >
            {{ 'notifications.downloads' | translate }}
          </h6>
          <div
            *ngFor="let item of notificationDownLaodList; let i = index"
            [ngClass]="{
              'border rounded': !isPopup,
              'mb-2': !isPopup && i < notificationDownLaodList.length - 1,
              'highlight-tile': !item.isVisited,
              'border-bottom':
                isPopup && i < notificationDownLaodList.length - 1
            }"
            class="notification-container p-2 d-flex pointer"
            (click)="readNotification(item, false)"
          >
            <div
              class="notification-msg-icon"
              [ngClass]="{
                bgImage: !isPopup || (!item.hasImage && isPopup),
                'mt-2 me-2': isPopup
              }"
            >
              <img
                *ngIf="item.hasImage"
                class="mt-0 profilePic"
                [src]="getEncodingURL(item.profileImage)"
              />
              <mat-spinner
                [hidden]="!item.isLoading"
                diameter="40"
              ></mat-spinner>
              <img
                *ngIf="!item.hasImage"
                class="mt-0 profilePic"
                src="assets/images/icons/icon_user.svg"
              />
            </div>
            <div class="notification-text fs-14">
              <div class="textPrimary fs-12 bold">
                {{ 'notification.heading.newDownload.assigned' | translate }}
              </div>
              <div class="text-left text-wrap line-height-normal">
                {{ item.notificationBodyParsed?.textTemplate }}
              </div>
              <div class="small text-gray-500">{{ item.formattedDate }}</div>
            </div>
          </div>
        </ng-container>
        <ng-template #noOtherNotifications>
          {{ 'notifications.nonotification' | translate }}
        </ng-template>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
<div *ngIf="isPopup">
  <a
    href="javascript:void(0);"
    class="d-flex text-center fs-14 p-3 mt-3 link viewMoreLink"
    (click)="onClickViewAll()"
    >{{ 'see.all.notifications' | translate }}({{ totalCount }})</a
  >
</div>
